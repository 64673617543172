import {GenericModel} from "@shared/models/generic.model";
import {IReturnTruckImage} from "@shared/interfaces/return-truck-image.interface";
import {EImageDefinitionType} from "@shared/enums/imageDefinitionType.enum";

export class ReturnTruckImageModel extends GenericModel<IReturnTruckImage> implements IReturnTruckImage {
    imageId: number;
    equipmentId: number;
    contactPersonId: number;
    returnTruckId: number;
    image: string;
    imageReal: number;
    AProdID: string;
    useType: string;
    isOptional: string;
    type: EImageDefinitionType | null;

    constructor(data?: Partial<IReturnTruckImage>) {
        super(data, ReturnTruckImageModel);
        this.imageId = data?.imageId ?? 0;
        this.equipmentId = data?.equipmentId ?? 0;
        this.contactPersonId = data?.contactPersonId ?? 0;
        this.returnTruckId = data?.returnTruckId ?? 0;
        this.image = data?.image ?? '';
        this.imageReal = data?.imageReal ?? 0;
        this.AProdID = data?.AProdID ?? '';
        this.useType = data?.useType ?? '';
        this.isOptional = data?.isOptional ?? '';
        this.type = data?.type ?? null;
    }
}