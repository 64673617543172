import {IReturnTruckConfiguration} from "@shared/interfaces/return-truck-configuration.interface";
import {GenericModel} from "@shared/models/generic.model";

export class ReturnTruckConfigurationModel extends GenericModel<IReturnTruckConfiguration> implements IReturnTruckConfiguration {
    category: string;
    code: string;
    description: string;
    id: string;
    returnTruckId: string;
    status: boolean | null;

    constructor(data?: Partial<IReturnTruckConfiguration>) {
        super(data, ReturnTruckConfigurationModel);
        this.category = data?.category ?? '';
        this.code = data?.code ?? '';
        this.description = data?.description ?? '';
        this.id = data?.id ?? '';
        this.returnTruckId = data?.returnTruckId ?? '';
        this.status = data?.status ?? null;
    }
}